export default {
  //用户信息记录
  state: {
    user: "",
    token: "",
  },
  mutations: {
    //初始化基本信息
    initUser(state) {
      let adminUser = window.sessionStorage.getItem("user");
      if (adminUser) {
        state.user = JSON.parse(adminUser);
        state.token = JSON.parse(window.sessionStorage.getItem("token"));
      }
    },
    //登录
    login(state, user) {
      state.user = user; //将返回来的信息设置到用户中去
      state.token = user.token; //token
      window.sessionStorage.setItem("user", JSON.stringify(state.user));
      window.sessionStorage.setItem("token", JSON.stringify(state.token));
    },
    //登出
    logout(state) {
      state.user = {};
      state.token = "";
    },
  },
  actions: {},
  modules: {},
};
