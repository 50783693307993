export default {
  //用户信息记录
  state: {
    headerNavData: {
      active: "0",
      list: [
        // {
        //   index: "1",
        //   name: "首页",
        //   subAction: "1-1",
        //   subList: [
        //     {
        //       index: "1-1",
        //       name: "后台首页",
        //       icon: "el-icon-platform-eleme",
        //       pathName: "/admin/home/index",
        //     },
        //     {
        //       index: "1-2",
        //       name: "广告管理",
        //       icon: "el-icon-eleme",
        //     },
        //     { index: "1-3", name: "操作日志", icon: "el-icon-delete-solid" },
        //   ],
        // }
      ],
    },
  },
  mutations: {
    //创建菜单
    createNavBar(state, menus) {
      let list = menus.map((item) => {
        let submenu = item.sub.map((v) => {
          return {
            icon: v.icon,
            name: v.title,
            pathname: v.path_name,
            id: v.id,
            fid: v.fid,
          };
        });
        return {
          name: item.title,
          id: item.id,
          fid: item.fid,
          subActive: "0",
          subList: submenu,
        };
      });
      state.headerNavData.list = list;
      window.sessionStorage.setItem(
        "headerNavData",
        JSON.stringify(state.headerNavData)
      );
    },
  },
  actions: {},
  modules: {},
};
