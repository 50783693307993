import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

//后台管理模块
import admin from "./modules/admin.js";
import menu from "./modules/menu.js";
import user from "./modules/user.js";
import lang from "./modules/lang.js";

export default new Vuex.Store({
  modules: {
    admin,
    menu,
    user,
    lang,
  },
  /*
	state: {},
	mutations: {},
	actions: {},
	modules: {},
	*/
});
