const fontEndRoutes=[
  {
    path: "/", //设置默认指向的路径
    component: () => import("@/views/web/login/index"),
  },
  {
    path: "/login",
    component: () => import("@/views/web/login/index"),
  },
  {
    path: "/web",
    redirect: "web/index",
    component: () => import("@/views/web/layout/index"),
    children: [
      {
        path: "index",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/web/index/index"),
      },
      {
        path: "allGoods",
        meta: {
          title: "全部商品",
        },
        component: () => import("@/views/web/allGoods/index"),
      },
      {
        path: "warehouseProducts",
        meta: {
          title: "中国仓库产品",
        },
        component: () => import("@/views/web/warehouseProducts/index"),
      },
      {
        path: "displayCompetition",
        meta: {
          title: "陈列竞赛",
        },
        component: () => import("@/views/web/displayCompetition/index"),
      },
      // {
      //   path: "display",
      //   meta: {
      //     title: "陈列照",
      //   },
      //   component: () => import("@/views/web/display/index"),
      // },
      {
        path: "newProduct",
        meta: {
          title: "新品上线",
        },
        component: () => import("@/views/web/newProduct/index"),
      },
      {
        path: "presellProduct",
        meta: {
          title: "预售产品",
        },
        component: () => import("@/views/web/presellProduct/index"),
      },
      {
        path: "presellInfo",
        meta: {
          title: "预售产品",
        },
        component: () => import("@/views/web/presellProduct/info"),
      },
      {
        path: "marketing",
        meta: {
          title: "促销产品",
        },
        component: () => import("@/views/web/marketing/index"),
      },
      {
        path: "onePiece",
        meta: {
          title: "1件起订",
        },
        component: () => import("@/views/web/onePiece/index"),
      },
      {
        path: "ipSeries",
        meta: {
          title: "IP系列",
        },
        component: () => import("@/views/web/ipSeries/index"),
      },
      {
        path: "hotProducts",
        meta: {
          title: "热销产品",
        },
        component: () => import("@/views/web/heatProduct/index"),
      },
      {
        path: "goodsDetail",
        meta: {},
        component: () => import("@/views/web/goodsDetail/index"),
      },
      {
        path: "orderCenter",
        meta: {},
        component: () => import("@/views/web/orderCenter/index"),
        redirect: "web/orderCenter/bookingOrderList",
        children: [
          {
            path: "bookingOrderList",
            component: () =>
              import("@/views/web/orderCenter/components/bookingOrderList"),
          },
          {
            path: "bookingOrderInfo",
            component: () =>
              import("@/views/web/orderCenter/components/bookingOrderList/info"),
          },

          {
            path: "salesGoodsList",
            component: () =>
              import("@/views/web/orderCenter/components/salesGoodsList"),
          },
          {
            path: "couponsList",
            component: () =>
              import("@/views/web/orderCenter/components/couponsList"),
          },
          {
            path: "downloadList",
            component: () =>
              import("@/views/web/orderCenter/components/downloadList"),
          },
          {
            path: "applyAfterSalesList",
            component: () =>
              import("@/views/web/orderCenter/components/applyAfterSalesList"),
          },
          {
            path: "afterSalesRecord",
            component: () =>
              import("@/views/web/orderCenter/components/afterSalesRecord"),
          },
          {
            path: "personalCenter",
            component: () =>
              import("@/views/web/orderCenter/components/personalCenter"),
          },
          {
            path: "orderDetails",
            component: () =>
              import("@/views/web/orderCenter/components/orderDetails"),
          },
          {
            path: "afterSaleOrderDetails",
            component: () =>
              import(
                "@/views/web/orderCenter/components/afterSaleOrderDetails"
                ),
          },
          {
            path: "salesTurnover",
            component: () =>
              import("@/views/web/orderCenter/components/salesTurnover"),
          },
          {
            path: "collectionList",
            component: () =>
              import("@/views/web/orderCenter/components/collectionList"),
          },
          {
            path: "displaycatagory",
            component: () => import("@/views/web/orderCenter/components/displayCategory"),
          },
          {
            path: "catagory/images",
            component: () => import("@/views/web/orderCenter/components/displayCategory/images"),
          },
          {
            path: "catagory/imagesInfo",
            component: () => import("@/views/web/orderCenter/components/displayCategory/info"),
          },
        ],
      },
      {
        path: "shoppingCart",
        meta: {},
        component: () => import("@/views/web/shoppingCart/index"),
      },
      {
        path: "promotionPage",
        meta: {},
        component: () => import("@/views/web/promotionPage/index"),
      },
      {
        path: "noticeList",
        component: () => import("@/views/web/notice/noticeList/index"),
      },
      {
        path:"noticeDetail",
        component:() =>  import("@/views/web/notice/noticeDetail/index"),
      },
      {
        path:"displayGoodsList",
        component:() =>  import("@/views/web/ipSeries/displayGoods"),
      },
    ],
  },
];

export default fontEndRoutes;
