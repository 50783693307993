const backEndRoutes=[
  {
    path: "/adminLogin",
    component: () => import("@/views/admin/login/index"),
  },
  {
    path: "/admin",
    redirect: "/admin/home/index",
    component: () => import("@/views/admin/layout/index"),
    children: [
      {
        path: "home",
        meta: {
          title: "首页",
        },
        redirect: "/admin/home/index",
        component: () => import("@/views/admin/layout/empty"),
        children: [
          {
            path: "index",
            meta: {
              title: "后台首页",
            },
            component: () => import("@/views/admin/home/index"),
          },
          {
            path: "advertiseManagement",
            meta: {
              title: "广告管理",
            },
            component: () => import("@/views/admin/home/advertiseManagement"),
          },
          {
            path: "dowGoodsWtm",
            meta: {
              title: "重量材质导出",
            },
            component: () => import("@/views/admin/home/dowGoodsWtm"),
          },
          {
            path: "noticeMessage",
            meta: {
              title: "公告管理",
            },
            component: () => import("@/views/admin/home/noticeMessage"),
          },
          {
            path: "displayCompetition",
            meta: {
              title: "陈列竞赛",
            },
            component: () => import("@/views/admin/home/displayCompetition"),
          },
        ],
      },

      //商品模块 begin
      {
        path: "goods",
        meta: {
          title: "商品",
        },
        component: () => import("@/views/admin/layout/empty"),
        children: [
          {
            path: "goodsManagement",
            meta: {
              title: "商品管理",
            },
            component: () =>
              import("@/views/admin/goods/goodsManagement/index"),
          },
          {
            path: "series",
            meta: {
              title: "系列产品",
            },
            component: () => import("@/views/admin/goods/series/index"),
          },
          {
            path: "goodsType",
            meta: {
              title: "商品分类",
            },
            component: () => import("@/views/admin/goods/goodsType/index"),
          },
          {
            path: "discount",
            meta: {
              title: "折扣产品",
            },
            component: () => import("@/views/admin/goods/discount/index"),
          },
          {
            path: "discountGoodsList",
            meta: {
              title: "折扣详情",
            },
            component: () => import("@/views/admin/goods/discount/discountInfo"),
          },
          {
            path: "coupons",
            meta: {
              title: "优惠卷",
            },
            component: () => import("@/views/admin/goods/coupons/index"),
          },
          {
            path: "less",
            meta: {
              title: "满送",
            },
            component: () => import("@/views/admin/goods/less/index"),
          },
          {
            path: "occupancyQuery",
            meta: {
              title: "占用查询",
            },
            component: () => import("@/views/admin/goods/occupancyQuery/index"),
          },
          {
            path: "certificationDocument",
            meta: {
              title: "认证文件",
            },
            component: () =>
              import("@/views/admin/goods/certificationDocument/index"),
          },
          {
            path: "certificationType",
            meta: {
              title: "认证类型",
            },
            component: () =>
              import("@/views/admin/goods/certificationType/index"),
          },
          {
            path: "exportCredential",
            meta: {
              title: "认证导出",
            },
            component: () => import("@/views/admin/goods/exportCredential"),
          },
          {
            path: "exportGoods",
            meta: {
              title: "商品导出",
            },
            component: () => import("@/views/admin/goods/exportGoods"),
          },
          {
            path: "presellGoods",
            meta: {
              title: "预售商品",
            },
            component: () => import("@/views/admin/goods/presellGoods"),
          },
          {
            path: "renew",
            meta: {
              title: "重新上新",
            },
            component: () => import("@/views/admin/goods/renew"),
          },
          {
            path: "display",
            meta: {
              title: "陈列照",
            },
            component: () => import("@/views/admin/goods/display"),
          },
          {
            path: "displayInfo",
            meta: {
              title: "陈列照详情",
            },
            component: () => import("@/views/admin/goods/display/displayinfo"),
          },
          {
            path: "notArrive",
            meta: {
              title: "未到商品清单",
            },
            component: () => import("@/views/admin/goods/notArrive/index"),
          },
        ],
      },
      //商品模块 end
      //订单模块

      {
        path: "order",
        meta: {
          title: "",
        },
        component: () => import("@/views/admin/layout/empty"),
        children: [
          {
            path: "shoppingCartList",
            meta: {
              title: "购物车列表",
            },
            component: () => import("@/views/admin/order/shoppingCartList"),
          },
          {
            path: "orderList",
            meta: {
              title: "订单列表",
            },
            component: () => import("@/views/admin/order/orderList"),
          },
          {
            path: "recycleBinOrderList",
            meta: {
              title: "找回订单",
            },
            component: () => import("@/views/admin/order/recycleBinOrderList"),
          },
          {
            path: "materialQuery",
            meta: {
              title: "物料查询",
            },
            component: () => import("@/views/admin/order/materialQuery"),
          },
          {
            path: "orderAfter",
            meta: {
              title: "售后列表",
            },
            component: () => import("@/views/admin/order/orderAfter"),
          },
          {
            path: "orderAfterCheck",
            meta: {
              title: "售后审核",
            },
            component: () => import("@/views/admin/order/orderAfterCheck"),
          },
          {
            path: "apreorder",
            meta: {
              title: "预售订单",
            },
            component: () => import("@/views/admin/order/presellOrder"),
          },
          {
            path: "cartClean",
            meta: {
              title: "找回购物车",
            },
            component: () => import("@/views/admin/order/cartClean"),
          },

        ],
      },
      //用户模块
      {
        path: "user",
        meta: {
          title: "",
        },
        component: () => import("@/views/admin/layout/empty"),
        children: [
          {
            path: "userList",
            meta: {
              title: "用户列表",
            },
            component: () => import("@/views/admin/user/userList"),
          },
        ],
      },
      //权限管理
      {
        path: "authManage",
        meta: {
          title: "",
        },
        component: () => import("@/views/admin/layout/empty"),
        children: [
          {
            path: "superManage",
            meta: {
              title: "管理员列表",
            },
            component: () => import("@/views/admin/authManage/superManage"),
          },
          {
            path: "manageGroupList",
            meta: {
              title: "查看管理组",
            },
            component: () => import("@/views/admin/authManage/manageGroupList"),
          },
          {
            path: "rulesList",
            meta: {
              title: "权限列表",
            },
            component: () => import("@/views/admin/authManage/rulesList"),
          },
        ],
      },
    ],
  },
];

export default backEndRoutes;
