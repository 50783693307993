import locale from "element-ui/lib/locale";
import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";

export default {
  state: {},
  mutations: {
    //设置语言
    setLange(state, lang) {
      if (lang == "cn") {
        locale.use(zhLocale);
      }
      if (lang == "en") {
        locale.use(enLocale);
      }
    },
  },
  actions: {},
  modules: {},
};
