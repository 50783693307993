export default {
  //用户信息记录
  state: {
    webUser: "",
    webToken: "",
  },
  mutations: {
    //登录
    loginWeb(state, user) {
      state.webUser = user; //将返回来的信息设置到用户中去
      state.webToken = user.token; //token

      window.sessionStorage.setItem("webUser", JSON.stringify(state.webUser));
      window.sessionStorage.setItem("webToken", JSON.stringify(state.webToken));
    },
    //登出
    logoutWeb(state) {
      state.user = {};
      state.token = "";
      window.sessionStorage.setItem("webUser", "");
      window.sessionStorage.setItem("webToken", "");
    },
  },
  actions: {},
  modules: {},
};
